/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';

import Layout from '../../components/Layout';
import ArticleRoll from '../../components/ArticleRoll';

const StoryIndexPage = () => (
    <Layout>
        <ArticleRoll />
    </Layout>
);

export default StoryIndexPage;
